<template>
  <b-card>
    <div>
      <div class="mb-2 flex justify-between">
        <h4 class="text-2xl text-black ">
          <strong>Broadcast</strong>
        </h4>
        <div class="rounded-lg px-1 flex items-center bg-[#F8F8F8]">
          <img
            width="30"
            src="https://storage.googleapis.com/komerce/assets/LP-Komerce/komchat.svg"
            alt="logo-komchat"
          >
          <div class="text-lg">
            <strong class="text-black flex items">Komchat</strong>
          </div>
        </div>
      </div>
      <div class="gap-4 w-full grid md:flex">
        <div class="flex justify-between border md:w-[35%] w-[100%] border-1 p-1 rounded-md shadow-md gap-3 items-center">
          <b-img
            fluid
            center
            src="https://storage.googleapis.com/komerce/assets/illustration/profile-illus-wa.svg"
            alt="icon-profile"
          />
          <div class="">
            <div
              v-if="!whatsAppList.length"
              class="flex mb-[3px] gap-2 items-center"
            >
              <b-img
                fluid
                src="https://storage.googleapis.com/komerce/assets/icons/danger-yellow.svg"
                alt=""
              />
              <div class="lg:w-[13rem] text-black">
                <h6 class="text-black">
                  Tidak ada nomor Whatsapp Aktif
                </h6>
              </div>
            </div>
            <div
              v-else
              class="overflow-y-auto h-[100px] max-h-[100px] p-[5px]"
            >
              <div
                v-for="(item, index) in whatsAppList"
                :key="index"
                class="d-flex  justify-content-between mb-1 border border-1 bg-gray-100 px-[2px] rounded-[8px]"
              >
                <div
                  class="mr-1 text-[14px] font-[500] align-self-center p-[5px]"
                  :class="!item.is_scan ? 'text-muted' : 'text-black'"
                >
                  {{ item.phone_number }}
                </div>
                <img
                  :id="`More-Btn-` + item.id"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/more.svg"
                  alt=""
                >
                <b-popover
                  :target="`More-Btn-` + item.id"
                  triggers="hover"
                  placement="left"
                  custom-class="w-33 z-50 rounded-[8px]"
                >
                  <div
                    v-if="!item.is_scan"
                    type="button"
                    class="mb-1"
                    @click="setNumb(item, 'scan')"
                  >
                    Scan
                  </div>
                  <div
                    type="button"
                    class=""
                    @click="setNumb(item, 'delete'); $root.$emit('bv::hide::popover')"
                  >
                    Hapus
                  </div>
                </b-popover>
              </div>
            </div>
            <div>
              <div
                v-if="isLoading"
                class="mb-[5px] text-black"
              >
                Silahkan tambahkan nomor agar whatsapp otomatis kamu aktif
              </div>
              <b-button
                size="sm"
                variant="primary"
                :class="whatsAppList.length >= 3? 'hidden': ''"
                class="w-full mt-[5px] text-white"
                @click="$router.push('/opsional-feature/notifikasi-wa-premium')"
              >
                Tambah nomor
              </b-button>
            </div>
          </div>
        </div>
        <div class="d-none">
          <div
            v-for="(item, index) in cardBroadcast"
            :key="index"
            class="border rounded-md w-[21.6%] flex flex-col justify-between border-1 p-1 shadow-md"
          >
            <div class="flex mb-1 items-center gap-2">
              <img
                :src="item.icon"
                alt=""
              >
              <div class="text-black">
                {{ item.text }}
              </div>
            </div>
            <div
              v-if="item.text === 'Expired'"
              class="text-xs"
            >
              Paket berakhir pada
            </div>
            <div class="mb-1">
              5000/20.000
            </div>
            <div>
              <b-button
                size="sm"
                variant="outline-primary"
              >
                {{ item.cta }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex mt-3 gap-4 md:gap-0 justify-between">
      <div>
        <BInputGroup class="input-group-merge">
          <BInputGroupPrepend is-text>
            <b-img
              fluid
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
              alt="search"
            />
          </BInputGroupPrepend>
          <BFormInput
            v-model="broadcast.search"
            size="md"
            placeholder="Cari berdasarkan judul"
            style="padding: 8px 1rem"
            @input="handleSearchReceiver"
          />
        </BInputGroup>
      </div>
      <div>
        <b-button
          variant="primary"
          @click="$router.push('/broadcast/send-message')"
        >
          Broadcast
        </b-button>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col>
        <BOverlay
          :show="broadcast.loading"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BTable
            id="scroll"
            ref="scroll"
            :items="items"
            :fields="fieldsBroadcastTable"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
            sticky-header="500px"
            class="mt-1"
            @scroll.native="handleScroll"
          >
            <template #cell(tittle)="data">
              <div class="grid">
                <div><span class="text-black">{{ data.item.title }}</span></div>
                <span class="text-[#7A8489]">{{ data.item.schedule }}</span>
              </div>
            </template>
            <template #cell(contact_total)="data">
              <span class="text-black">{{ data.item.total }}</span>
            </template>
            <template #cell(succes)="data">
              <span class="text-[#34A770]">{{ data.item.success }}</span>
            </template>
            <template #cell(failed)="data">
              <span class="text-[#E31A1A]">{{ data.item.failed }}</span>
            </template>
            <template #cell(status)="data">
              <div>
                <div
                  v-show="data.item.status === 'PAUSED' || data.item.status === 'READY' "
                  class="bg-[#FFEDED]  rounded-lg py-[4px]"
                >
                  <div class="text-center text-[#E31A1A] font-[500]">
                    Pending
                  </div>
                </div>
                <div
                  v-show="data.item.status === 'FAILED'"
                  class="bg-[#FFEDED]  rounded-lg py-[4px]"
                >
                  <div class="text-center text-[#E31A1A] font-[500]">
                    Gagal
                  </div>
                </div>
                <div
                  v-show="data.item.status === 'SUCCESS'"
                  class="bg-[#DCF3EB]  rounded-lg py-[4px]"
                >
                  <div class="text-center text-[#34A770] font-[500]">
                    Selesai
                  </div>
                </div>
                <div
                  v-show="data.item.status === 'PROCESSING'"
                  class="bg-[#FFF2E2] rounded-lg py-[4px]"
                >
                  <div class="text-center text-[#FBA63C] font-[500]">
                    Mengirim
                  </div>
                </div>
              </div>
            </template>
            <template #cell(action)="data">
              <div
                v-if="data.item.status === 'PAUSED' "
                role="button"
                aria-readonly="true"
                :disabled="data.item.status === 'READY'"
                class="border items-center cursor-pointer gap-2 flex border-1 w-[108px] border-[#E2E2E2] py-[3px] px-[0.6rem] rounded-lg"
                @click="handleActionBroadcast(data.item.id,'resume')"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/play.svg"
                  alt="icon-play"
                  srcset=""
                >
                <div>Resume</div>
              </div>
              <div
                v-if="data.item.status === 'SUCCESS' || data.item.status === 'FAILED'"
                class="border items-center cursor-pointer gap-2 flex flex border-1 w-[108px] border-[#E2E2E2] py-[3px] px-[0.6rem] rounded-lg"
                @click="handleActionBroadcast(data.item.id,'resend')"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/send-2.svg"
                  alt="icon-send"
                  srcset=""
                >
                <div>Resend</div>
              </div>
              <div
                v-if="data.item.status === 'PROCESSING'"
                class="border items-center cursor-pointer gap-2 flex border-1 w-[108px] border-[#E2E2E2] py-[3px] px-[0.6rem] rounded-lg"
                @click="handleActionBroadcast(data.item.id,'pause')"
              >
                <img
                  class="w-1/6"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/pause.svg"
                  alt="icon pause"
                  srcset=""
                >
                <div>Pause</div>
              </div>
            </template>
          </BTable>
        </BOverlay>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BOverlay,
  BFormInput,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BPopover,
} from 'bootstrap-vue'
import { cardBroadcast, fieldsBroadcastTable } from '@/views/pages/komship/customer/Broadcast/config'
import { apiGetBroadcast, apiActionBroadcast } from './Broadcast/service/api.broadcast.service'

export default {
  components: {
    BCard,
    BOverlay,
    BFormInput,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BPopover,
  },
  data() {
    return {
      isCanAccessbroadcast: this.$store.state.whatsapp.dashboard_wa.subscription_plan_level > 1,
      isExpiredPackage: this.$store.state.whatsapp.dashboard_wa.whatsapp_expired_at === 0,
      cardBroadcast,
      fieldsBroadcastTable,
      whatsAppList: this.$store.state.komchat.whatsAppList,
      items: [],
      isLoading: false,
      broadcast: {
        cursor: '',
        limit: 10,
        search: '',
        loading: false,
        lastData: false,
        debounce: 0,
      },
    }
  },

  mounted() {
    this.getDataBroadcast()
    this.checkIsCanAccessBroadcast()
    this.$store.watch(
      state => state.komchat.whatsAppList,
      (newValue, oldValue) => {
        this.whatsAppList = newValue
      },
    )
  },
  methods: {
    async getDataBroadcast() {
      this.broadcast.loading = true
      this.broadcast.cursor = ''
      try {
        const response = await apiGetBroadcast(this.broadcast.search, this.broadcast.cursor, this.broadcast.limit)
        this.items = response.data.data
        const items = response.data.data
        this.broadcast.cursor = response.data.meta.pagination.next_cursor_str
        if (items.length < this.broadcast.limit || this.broadcast.cursor === '') {
          this.broadcast.lastData = true
        } else {
          this.broadcast.lastData = false
        }
        this.broadcast.loading = false
      } catch (error) {
        console.error(error)
        this.broadcast.loading = false
        this.$toast_error({ message: this.capitalizeWords(error.response.data.meta.message) })
      }
    },
    async getNextDataBroadcast() {
      if (!this.broadcast.lastData) {
        this.broadcast.loading = true
        try {
          const response = await apiGetBroadcast(this.broadcast.search, this.broadcast.cursor, this.broadcast.limit)
          const items = response.data.data
          this.items.push(...items)
          this.broadcast.cursor = response.data.meta.pagination.next_cursor_str
          if (items.length < this.broadcast.limit || this.broadcast.cursor === '') {
            this.broadcast.lastData = true
          } else {
            this.broadcast.lastData = false
          }
          this.broadcast.loading = false
        } catch (error) {
          console.error(error)
          this.broadcast.loading = false
          this.$toast_error({ message: this.capitalizeWords(error.response.data.meta.message) })
        }
      }
    },
    async checkIsCanAccessBroadcast() {
      await this.$store.dispatch('whatsapp/getUpdateDashboardWa')
      await this.$store.watch(
        state => state.whatsapp.dashboard_wa.subscription_plan_level,
        (newValue, oldValue) => {
          this.isCanAccessbroadcast = newValue
        },
      )
      if (!this.isCanAccessbroadcast && this.isExpiredPackage) {
        this.$router.push('/opsional-feature')
      } else if (!this.isCanAccessbroadcast) {
        this.$router.push('/aplikasiku')
      } else {
        this.$store.dispatch('komchat/getWhatsAppList')
        this.whatsAppList = this.$store.state.komchat.whatsAppList
      }
    },
    setNumb(data, value) {
      if (value === 'delete') {
        this.$router.push('opsional-feature/notifikasi-wa-premium')
      } else {
        this.$router.push({ name: 'koneksi-wa', params: { number: data } })
      }
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.getNextDataBroadcast()
      }
    },
    handleSearchReceiver() {
      clearTimeout(this.broadcast.debounce)

      // eslint-disable-next-line no-underscore-dangle
      this.broadcast.debounce = setTimeout(() => {
        this.getDataBroadcast()
      }, 2000)
    },
    async handleActionBroadcast(id, action) {
      try {
        const param = {
          id,
          action,
        }
        const data = await apiActionBroadcast({ param })
        if (data.data.meta.code === 200) {
          this.getDataBroadcast()
        }
      } catch (error) {
        this.$toast_error({ message: this.capitalizeWords(error.response.data.meta.message) })
      }
    },
    capitalizeWords(str) {
      if (!str) return str
      return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
    },
  },
}

</script>
